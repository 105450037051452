import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Navigation = ({ activeClass }) => {
  return (
    <Fragment>
      <nav className="main-menubar ul-li-right clearfix">
        <ul className="clearfix">
          <li>
            <Link to="/">home</Link>
          </li>
          <li><Link to="our-works">Our Works</Link>
          </li>
          <li>
            <Link to="about-us">About Us</Link>
          </li>
          <li>
            <Link to="contact-us">Contact Us</Link>
          </li>
          <li>
            <Link to="career">Career</Link>
          </li>
        </ul>
      </nav>
    </Fragment>
  );
};

export default Navigation;
