import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import AboutData from "../../data/about/about-title.json";
const [
  { title, titleSpan, paragraph1, paragraph2, img, name, nametitle },
] = AboutData;
const About = ({ sectionSpace, about }) => {
  return (
    <Fragment>
      <section
        id="about-section"
        className={`about-section ${sectionSpace} clearfix`}
      >
        <Container>
          {about.map((about,index)=>(
            <Row key={index} className="justify-content-lg-between justify-content-md-center">
            <Col lg="5" md="8" xs="12">
              <div className="about-image">
                <span className="item-image">
                  <img
                    src={about.image}
                    alt="image_not_found"
                  />
                </span>
              </div>
            </Col>

            <Col lg="6" md="8" xs="12">
              <div className="about-content">
                <h2 className="title-text mb-45">
                  {about.title}
                </h2>
                <p className="mb-30">{about.description}</p>
                {/* <div className="hero-content">
                  <small className="hero-signature mb-30">
                    <img src={img} alt="signature_not_found" />
                  </small>
                  <span className="hero-name">
                    {name} <small className="hero-title">{nametitle}</small>
                  </span>
                </div> */}
              </div>
            </Col>
          </Row>
          ))}
        </Container>
      </section>
    </Fragment>
  );
};

export default About;
