import react from "react";
import { useState } from "react";
import { Col, Form, Row } from "reactstrap";
import { useRef } from "react";
import axios from "axios";
import Popup from "reactjs-popup";

function ApplyForm({name}){
    const[firstName, setFirstName] = useState("")
    const[lastName, setLastName] = useState("")
    const[email, setEmail] = useState("")
    const[phone, setPhone] = useState("")
    const[summery, setSummery] = useState("")
    const[cv, setCv] = useState("")
    const [checkBox, setCheckBox] = useState(false)

    const btn = useRef(null)
    const handleClick=()=>{
        btn.current.click();
    }
    const handleSubmit = async (e) =>{
        const formData = new FormData();
        e.preventDefault();
        formData.append('first_name',firstName)
        formData.append('last_name',lastName)
        formData.append('email',email)
        formData.append('phone',phone)
        formData.append('personal_summery',summery)
        formData.append('cv',cv)
        formData.append('can_we_process_personal_info',true)
        try{
            let response = await axios.post("http://127.0.0.1:8000/Career/applyform/", formData,{
                method: "POST",
                headers:{
                    'content-type': "multipart/form-data"
                }
            })
            // if(response.status == 201){
            //     <Popup trigger={true} position="right center">
            //         <p>Application successfull!</p>
            //     </Popup>
            // }
            console.log(response)
        }
        catch(err){}
        

    }
    return(
        <>
        <div className=" container apply-form">
        <form onSubmit={handleSubmit} className="row g-3 needs-validation" novalidate>
            <div className="col-md-6">
                <label htmlFor="validationCustom01" className="form-label">First name</label>
                <input type="text" name="fist_name" onChange={e=>setFirstName(e.target.value)} className="form-control" id="validationCustom01" required/>
                <div className="valid-feedback">
                Looks good!
                </div>
            </div>
            <div className="col-md-6">
                <label htmlFor="validationCustom02" className="form-label">Last name</label>
                <input type="text" name="last_name" onChange={e=>setLastName(e.target.value)} className="form-control" id="validationCustom02" required/>
                <div className="valid-feedback">
                Looks good!
                </div>
            </div>
            <div className="col-md-4">
                <label htmlFor="validationCustom03" className="form-label">Email</label>
                <input type="text" name="email" onChange={e=>setEmail(e.target.value)} className="form-control" id="validationCustom03" required/>
                <div class="invalid-feedback">
                Please provide a valid email address.
                </div>
            </div>
            <div className="col-md-4">
                <label htmlFor="validationCustom03" className="form-label">Phone</label>
                <input type="text" name="phone" onChange={e=>setPhone(e.target.value)} className="form-control" id="validationCustom03" required/>
                <div className="invalid-feedback">
                Please provide a valid Phone number.
                </div>
            </div>
            <div className="col-md-4">
                <label htmlFor="validationCustom03" className="form-label">Job Position</label>
                <input type="text" className="form-control" id="validationCustom03" value={name} onChange={``} required/>
                <div className="invalid-feedback">
                Please provide a valid email address.
                </div>
            </div>
            <div className="col-md-12">
                <label htmlFor="exampleFormControlTextarea1" className="form-label">Personal Summery</label>
                <textarea  className="form-control" name="summery" id="exampleFormControlTextarea1" onChange={e=>setSummery(e.target.value)} required/>
                <div className="invalid-feedback">
                Please provide your personal summery here.
                </div>
            </div>
            <div className="col-md-12">
            <label htmlFor="validationCustom03" className="form-label">Upload Your Resume/CV</label>
                <div className="upload-file border " style={{ display: 'flex', flexDirection: 'column' }}>
                   <input
                   ref={btn} 
                   type="file"
                   name="cv"
                   style={{ display: 'none' }}
                   onChange={e=>setCv(e.target.files[0])}
                   >
                   </input>
                   <button onClick={()=> handleClick()} className="file-button" type="button">Select File</button>
            
                   <div className="upload-file-name mt-3">
                   {cv===""?<span>Choose File</span>:<span>{cv.name}</span>}
                   </div>
                </div>
            </div>
            
            <div className="col-12">
                <div className="form-check">
                <input className="form-check-input" type="checkbox" name="can_we_process_personal_info" id="invalidCheck" onChange={e=>setCheckBox(e.target.value)} required/>
                <label className="form-check-label" for="invalidCheck">
                    By Applying the jon you must agree with CodeBreakers's Terms and Condition and Privacy Policies.
                </label>
                <div className="invalid-feedback">
                    You must agree before submitting.
                </div>
                </div>
            </div>
            <div className="col-12">
                <button  className="submit-button" type="submit" value="submit" name="submit">Submit form</button>
            </div>
        </form>
        </div>
        </>
    );
}
export default  ApplyForm;