import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import featureDataOne from "../../data/feature/feature-one.json";
import featureDataTwo from "../../data/feature/feature-two.json";
const [{ paragraph1, paragraph2, subTitle, title, span }] = featureDataOne;
const FeatureFour = ({work}) => {
  const [screen,setScreen] = useState(false)

  useEffect(()=>{
    const checkScreen = window.matchMedia("(max-width: 800px)").matches
    setScreen(checkScreen)
  });
  return (
    <Fragment>
      <section
        id="features-section"
        className="features-section sec-ptb-160 clearfix"
      >
        <Container>
        {work.map((work,index)=>(
            screen==false?(index%2 !== 0?
            <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="6" md="8">
                <div 
                className="feature-content" 
                data-aos="fade-right"
                data-aos-delay="200"
                >
                  <div className="section-title mb-60">
                    <span className="sub-title mb-15">Our Services</span>
                    <h2 className="title-text mb-0">
                      {work.sevices_title}
                    </h2>
                  </div>

                  <p className="mb-30">{work.services_description}</p>
                  {/* <p className="mb-60">{paragraph2}</p> */}
                </div>
              </Col>
              <Col lg="5" md="10">
                <div className="feature-image-8">
                  <span
                    className="phone-image"
                    data-aos="fade-left"
                    data-aos-delay="400"
                  >
                    <img
                      src={work.services_banner}
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          :
          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="10">
                <div className="feature-image-8">
                  <span
                    className="phone-image"
                    data-aos="fade-right"
                    data-aos-delay="200"
                  >
                    <img
                      src={work.services_banner}
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>

              <Col lg="6" md="8">
                <div 
                className="feature-content" 
                data-aos="fade-left"
                data-aos-delay="400"
                >
                  <div className="section-title mb-60">
                    <span className="sub-title mb-15">Our Services</span>
                    <h2 className="title-text mb-0">
                      {work.sevices_title}
                    </h2>
                  </div>

                  <p className="mb-30">{work.services_description}</p>
                </div>
              </Col>
            </Row>
          </div>
          )
          :
          (
            <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="10">
                <div className="feature-image-8">
                  <span
                    className="phone-image"
                    data-aos="fade-right"
                    data-aos-delay="200"
                  >
                    <img
                      src={work.services_banner}
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>

              <Col lg="6" md="8">
                <div 
                className="feature-content" 
                data-aos="fade-left"
                data-aos-delay="400"
                >
                  <div className="section-title mb-60">
                    <span className="sub-title mb-15">Our Services</span>
                    <h2 className="title-text mb-0">
                      {work.sevices_title}
                    </h2>
                  </div>

                  <p className="mb-30">{work.services_description}</p>
                </div>
              </Col>
            </Row>
          </div> 
          )
          ))}
        </Container>
      </section>
    </Fragment>
  );
};

export default FeatureFour;
