import React from "react";

import FormikForm from "./Formik";
import { postContact } from '../../service/contactService';

class ContactForm extends React.Component {
  render() {
    let values = {
      name: "",
      company: "",
      email: "",
      phone:"",
      messages: "",
    };
    return <FormikForm values={values} handleSubmit={this.handleFormSubmit} />;
  }
  handleFormSubmit = async (values, setSubmitting) => {
    //console.log(values);
    try{
      const response = await postContact(values)
      if(response.status == 201){
        console.log("Success")
      }
    }
    catch(err){}
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };
}

export default ContactForm;
