import React, { Component, Fragment } from 'react'
import PageTitle from '../../components/utility';
import { LayoutFluid, LayoutTwo } from '../../layouts';
import { BreadCrumbOne, BreadCrumbTwo } from '../../components/BreadCrumb';
import JobDescription from '../../components/career/jobDescription';
import http from "../../service/httpService";


class JobDetails extends Component {
    constructor(props){
        super(props);
        this.state={
            jobdetails:[],
            keyLenght:0
        }
    }
    async componentDidMount (){
      try {
          const {data: jobdetails} = await http.get(`/Career/jobdescription/${this.props.match.params.id}`)
          console.log(jobdetails)
          this.setState({jobdetails});
          
      } catch (error) {
          
      }
    
    }

   render(){
    const {jobdetails} = this.state
    // console.log(jobdetails)
    return ( 
         <Fragment>
         <PageTitle
             PageTitle="Job Details"
             favicon="/assets/images/logo/tab-logo.png"
         />
         <LayoutTwo
            activeClass="active"
            wrapperClass="home-page-8"
            sectionSpace="sec-ptb-160 pb-0">
            <BreadCrumbTwo/>
             <JobDescription jobdetails={jobdetails}></JobDescription>
             </LayoutTwo>

     </Fragment>
     );
   }
}

export default JobDetails;