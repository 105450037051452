import React, { Component } from 'react'
import { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { AOS } from 'aos';
import http from "../../service/httpService";
import { Link } from 'react-router-dom';

class JobDescription extends Component  {
//   constructor(props){
//     super(props);
//     this.state={
//       jobdetails:[],
//         keyLenght:0
//     }
// }
// async componentDidMount (){
//   try {
//       const {data: jobdetails} = await http.get(`/Career/jobdescription/${this.props.id}`)
//       this.setState({jobdetails});
      
//   } catch (error) {
      
//   }

// }

   render(){
    const keyRespon = String(this.props.jobdetails['title_two_desc']).split('\n');
    const skills = String(this.props.jobdetails['title_three_desc']).split('\n');
    const exp = String(this.props.jobdetails['title_four_desc']).split('\n');
    return ( 
      <Fragment>
          <div className="feature-item">
          <Row className="justify-content-lg-between justify-content-md-center">
          <Col lg="7" md="6">
              <div className="feature-content left-contents">
                <div className="section-title mb-30" data-aos="fade-up" data-aos-delay="100">
                  <span className="sub-title mb-15">Job Description</span>
                  <h1 className="title-text mb-0">
                    {this.props.jobdetails.title_one}
                  </h1>
                  <p className="mb-30">{this.props.jobdetails.title_one_desc}</p>
                </div>
                <div className='title-text text-left mb-0' data-aos="fade-up" data-aos-delay="200">
                  <h3>{this.props.jobdetails.title_two}</h3>
                  <ul>
                    {
                      keyRespon.map((contents)=>(
                        <li>{contents}</li>
                      ))
                      }
                  </ul>
                </div>
                <div className='title-text text-left mb-0'data-aos="fade-up" data-aos-delay="300">
                  <h3>{this.props.jobdetails.title_three}</h3>
                  <ul>
                      {
                        skills.map((contents)=>(
                          <li>{contents}</li>
                        ))
                      }
                  </ul>
                </div>
                <div className='title-text text-left mb-0' data-aos="fade-up" data-aos-delay="400">
                  <h3>{this.props.jobdetails.title_four}</h3>
                  <ul>
                      {
                        exp.map((contents)=>(
                          <li>{contents}</li>
                        ))
                      }
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg="5" md="9">
              <div className=' right-contents'>
              <div className="float-right">
                <div className='apply'>
                  <Link to={`/${this.props.jobdetails.title_one}/new-application`}> Apply Now</Link>
                  {/* <a href={`/${this.props.jobdetails.id}/new-application`}>Apply Now</a> */}
                </div>
                <div className='right-description'>
                  <h6>Department:</h6>
                  <span>{this.props.jobdetails.department}</span>
              </div>
                <div className='right-description'>
                  <h6>Location</h6>
                  <span>{this.props.jobdetails.location}</span>
              </div>
              <div className='right-description'>
                  <h6>Emplyement Type</h6>
                  <span>{this.props.jobdetails.employee_type}</span>
              </div>
              <div className='right-description'>
                  <h6>Compansation</h6>
                  <span>{this.props.jobdetails.compansation}</span>
              </div>
              </div>
              </div>
            </Col>
          </Row>
        </div>
      </Fragment>
   );
   }
}

export default JobDescription;