import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getBlog } from "../../../service/blogService";
const postWidget = ({ title, blogs }) => {
  console.log(blogs)
  return (
    <Fragment>
      <div className="widget widget_cixposttype ul-li-block clearfix">
        <h2 className="widget_title">{title}</h2>
        <ul className="clearfix">
         
            {blogs.map(blogs=>(
               <li>
              <span className="post-date">April 04, 2021</span>
              <Link to="#!" className="item-title">
                {blogs.title}
              </Link>
              </li>
            ))}
        </ul>
      </div>
    </Fragment>
  );
};

export default postWidget;
