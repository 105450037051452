import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ScrollToTop } from "./components/ScrollToTop";
import { HomePageFive } from "./pages";

import { FeaturePageTwo } from "./pages";
import { AboutPage } from "./pages";
import { ContactPage } from "./pages";
import { ErrorPage } from "./pages";
import { BlogDetailsTwoPage } from "./pages";

import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/scss/bootstrap.scss";
import "react-modal-video/scss/modal-video.scss";
import "./assets/css/animate.css";
import "./assets/css/unicons.css";
import "./assets/css/flaticon.css";
import "./assets/css/fontawesome-all.css";
import "./assets/scss/style.scss";
import Career from "./pages/others/career";
import JobDetails from "./pages/others/job-details";
import ApplyJob from "./pages/others/applyJob";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/blog-details/:id" component={BlogDetailsTwoPage} />
          <Route path="/404" component={ErrorPage} />
          <Route path="/contact-us" component={ContactPage} />
          <Route path="/about-us" component={AboutPage} />
          <Route path="/career" component={Career} />
          <Route path="/job-details/:id" component={JobDetails} />
          <Route path="/:position/new-application" component={ApplyJob}></Route>
          <Route
            path="/our-works"
            component={FeaturePageTwo}
          />
          <Route path="/" component={HomePageFive} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
