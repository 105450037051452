import React, { Fragment } from "react";
import PageTitle from "../../components/utility";
import { LayoutFluid } from "../../layouts";
import { BreadCrumbOne } from "../../components/BreadCrumb";

import { Map } from "../../components/Map";
import LayoutTwo from '../../layouts/LayoutTwo';
const ContactPage = () => {
  const description = "We are wre excited to talk with you. you can easily fix a meeting with your available time. CodeBreaker is ready to give you support and answers to your query."
  return (
    <Fragment>
      <PageTitle
        PageTitle="Arventure-Contact Us"
        favicon="assets/images/logo/tab-logo.png"
      />
      <LayoutTwo
            activeClass="active"
            wrapperClass="home-page-8"
            sectionSpace="sec-ptb-160 pb-0">
        <main>
          <BreadCrumbOne
            title="contact with us"
            pageName="contact us"
            curentPageName="contact us"
            banner="/assets/images/breadcrumb/contact-us.png"
            description={description}
          />

          <Map />
        </main>
      </LayoutTwo>
    </Fragment>
  );
};

export default ContactPage;
