import React, { Fragment } from "react";
import PageTitle from "../../components/utility";
import { LayoutFluid, LayoutTwo } from "../../layouts";
import { BreadCrumbOne } from "../../components/BreadCrumb";
import FeaturesNav from "../../components/FeaturesNav/FeaturesNav";
import { FeatureSeven } from "../../components/Features";
import { getPortfolio } from '../../service/portfolioService';

const FeaturePageTwo = () => {
  const description = "Here are a few recent projects that we completed for our valuable clients. Our highly skilled software engineers constantly endeavor to take on new, complex tasks to push their capabilities. We invite you to provide us with a difficult task using your sophisticated business logic. Budget concerns are unnecessary; let's just FIX A MEETING."
  return (
    <Fragment>
      <PageTitle
        PageTitle="Arventure-Our Works"
        favicon="/assets/images/logo/tab-logo.png"
      />
      <LayoutTwo
            activeClass="active"
            wrapperClass="home-page-8"
            sectionSpace="sec-ptb-160 pb-0">
        <main>
          <BreadCrumbOne
            title="Our Works"
            pageName="Features"
            curentPageName="team members"
            banner="/assets/images/breadcrumb/banner-1.png"
            description={description}
          />
          <FeatureSeven sectionSpace="sec-ptb-160 pb-0" />
        </main>
      </LayoutTwo>
    </Fragment>
  );
};

export default FeaturePageTwo;
