import React, { Component, Fragment, useEffect, useState } from 'react'
import { BreadCrumbOne } from '../../components/BreadCrumb';
import Jobs from '../../components/career/jobs';
import PageTitle from '../../components/utility';
import { LayoutFluid } from '../../layouts';
import { getCareers } from '../../service/careerService';
import LayoutTwo from '../../layouts/LayoutTwo';


const Career = ()=> {
    const [career, setCareer] = useState([])
    const description = "CodeBreakers always welcome the people who have madness with programming and want to take the challenge to a new dimension. If you have dedication and passion in different areas of software engineering and want to create something new, our door is always open for you."

    useEffect (()=>{
        getCareer()
    },[])
    const getCareer= async()=>{
        try{
            const response = await getCareers()
            setCareer(response.data)
        }
        catch(err){

        }
    }
    return (  
        <Fragment>
            <PageTitle
                PageTitle="Arventure-Career"
                favicon="assets/images/logo/tab-logo.png"
            />
                <LayoutTwo
                    activeClass="active"
                    wrapperClass="home-page-8"
                    sectionSpace="sec-ptb-160 pb-0">
                    <main>
                        <BreadCrumbOne
                        title="We are More then a team"
                        pageName="about us"
                        curentPageName="Career"
                        banner="/assets/images/breadcrumb/interview-banner.png"
                        description={description}
                        >
                        </BreadCrumbOne>
                    </main>
                    <Jobs career={career}></Jobs>
            </LayoutTwo>
        </Fragment>
    );
}

export default Career;