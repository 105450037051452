import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const BreadCrumbOne = ({ title, pageName, curentPageName,banner,description }) => {
  return (
    <Fragment>
      <section
        id="breadcrumb-section"
        className="breadcrumb-section mma-container clearfix">
        <div className="design-image-1 clearfix">
          <small className="image-1" data-aos="fade-up" data-aos-delay="100">
            <img
              src={banner}
              alt="image_not_found"
            />
          </small>
        </div>

        <div className="container">
          <div className="row justify-content-lg-start justify-content-md-center">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className="breadcrumb-content">
                <div className="section-title">
                  <h2 className="title-text mb-15">{title}</h2>
                  <p className="paragraph-text mb-0">
                    {description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <small className="cross-image-1 spin-image mma-item">
          <img src="/assets/images/shapes/cross.png" alt="image_not_found" />
        </small>
        <small className="cross-image-2 spin-image mma-item">
          <img src="/assets/images/shapes/cross.png" alt="image_not_found" />
        </small>

        <small className="box-image-1 spin-image mma-item">
          <img src="/assets/images/shapes/box.png" alt="image_not_found" />
        </small>
        <small className="box-image-2 spin-image mma-item">
          <img src="/assets/images/shapes/box.png" alt="image_not_found" />
        </small>

        <small className="flow-image-1 zoominout-image mma-item">
          <img src="/assets/images/shapes/flow-1.png" alt="image_not_found" />
        </small>
        <small className="flow-image-2 zoominout-image mma-item">
          <img src="/assets/images/shapes/flow-2.png" alt="image_not_found" />
        </small>

        <small className="circle-half-1 spin-image mma-item">
          <img
            src="/assets/images/shapes/circle-half.png"
            alt="image_not_found"
          />
        </small>
        <small className="circle-half-2 spin-image mma-item">
          <img
            src="/assets/images/shapes/circle-half.png"
            alt="image_not_found"
          />
        </small>

        <small className="circle-image-1 zoominout-image mma-item">
          <img src="/assets/images/shapes/circle.png" alt="image_not_found" />
        </small>
      </section>
    </Fragment>
  );
};

export default BreadCrumbOne;
