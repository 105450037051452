import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import BlogOne from "../../data/blog/blog-one.json";

import "../../../node_modules/swiper/swiper-bundle.css";
import titleEight from "../../data/title/title-eight.json";
import { Link } from "react-router-dom";
SwiperCore.use(Pagination);

const [{ title, subTitle }] = titleEight;
const BlogSlider = ({ sectionSpace, blog }) => {
  return (
    <div className={`blog-section ${sectionSpace} clearfix`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title mb-100 text-center">
              <h2 className="title-text mb-0">{title}</h2>
            </div>
          </div>
        </div>

        <div id="blog-carousel" className="blog-carousel">
          <Swiper
            pagination={{ clickable: true }}
            spaceBetween={30}
            slidesPerView={3}
            loop={true}
            autoplay={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              600: {
                slidesPerView: 2,
              },

              1200: {
                slidesPerView: 3,
              },
            }}
          >
            {blog.map((item, index) => {
              return (
                <SwiperSlide className="item" key={index}>
                  <div className="blog-grid-item clearfix">
                    <div className="blog-image">
                      <img src={item.image} alt="image_not_found " />
                      <div className="post-date">
                        <strong className="date-text">03</strong>
                        <span className="month-text">Mar</span>
                      </div>
                    </div>
                    <div className="blog-content">
                      <a href={`#`} className="tag-link">
                        {item.catagory}
                      </a>
                      <h3 className="blog-title mb-0">
                        <Link to={`blog-details/${item.id}`}  className="title-link">{item.title}</Link>
                        {/* <a href={`blog-details/${item.id}`} className="title-link">
                          {item.title}
                        </a> */}
                      </h3>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default BlogSlider;
