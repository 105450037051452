import React, { Fragment, useEffect, useState, Component } from "react";
import PageTitle from "../../components/utility";
import { LayoutFluid } from "../../layouts";
import { BreadCrumbOne } from "../../components/BreadCrumb";
import { BlogDetailsTwo } from "../../components/Blogs";
import axios from "axios";
import LayoutTwo from '../../layouts/LayoutTwo';

class BlogDetailsTwoPage extends Component {
  constructor(props){
    super(props);
    this.state ={
      blogDetails:[]
    }
  }
  async componentDidMount(){
      try{
      const {data:blogDetails} = await axios.get(`/Blog/Blog/${this.props.match.params.id}`)
      this.setState({blogDetails});
      
    }
    catch(err){
      console.log(err)
    }
  }
  // useEffect=()=>{
  //   getBlogs()
  // }
  // const getBlogs= async()=>{
  //   try{
  //     const response = await http.get(`https://admin.codebreakersbd.com/Blog/Blog/${props.match.params.id}`)
  //     setBlog(response.data)
  //   }
  //   catch(err){
  //     console.log(err)
  //   }
  // }

  render(){
    const {blogDetails} = this.state
    return (
      <Fragment>
        <PageTitle
          PageTitle="Blog Details With Sidebar - Appal - Mobile App Leanding Page"
          favicon="/assets/images/logo/tab-logo.png"
        />
       <LayoutTwo
            activeClass="active"
            wrapperClass="home-page-8"
            sectionSpace="sec-ptb-160 pb-0">
          <main>
            <BreadCrumbOne
              title="Blog Details"
              pageName="blog details"
              curentPageName="blog details"
            />
            <BlogDetailsTwo blog={blogDetails}  sectionSpace="sec-ptb-160 pb-0" />
            {/* <BlogDetailsTwo  sectionSpace="sec-ptb-160 pb-0" /> */}
          </main>
        </LayoutTwo>
      </Fragment>
    );
  }
};

export default BlogDetailsTwoPage;
