import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/utility";
import { LayoutFluid, LayoutTwo } from "../../layouts";
import { BreadCrumbOne } from "../../components/BreadCrumb";
import { About } from "../../components/About";
import { getAbout } from '../../service/aboutService';


const AboutPage = () => {
  const [about, setAbout] = useState([])
  const description="We have different experts in specific language and their frameworks. Our engineers are familiar with the most updated technologies and tools which are available. In some existing projects, our experts are capable of doing work with compatible versions of your system."

  useEffect(()=>{
    getAboutUs()
  },[])

  const getAboutUs = async()=>{
    try{
      const response = await getAbout()
      setAbout(response.data)
    }
    catch(err){}
  }
  return (
    <Fragment>
      <PageTitle
        PageTitle="Arventure-About us"
        favicon="assets/images/logo/tab-logo.png"
      />
      <LayoutTwo
            activeClass="active"
            wrapperClass="home-page-8"
            sectionSpace="sec-ptb-160 pb-0">
        <main>
          <BreadCrumbOne
            title="We are More then a team"
            pageName="about us"
            curentPageName="about us"
            banner="/assets/images/breadcrumb/about-banner.png"
            description={description}
          />
          <About about={about} sectionSpace="sec-ptb-160" />
        </main>
      </LayoutTwo>
    </Fragment>
  );
};

export default AboutPage;
