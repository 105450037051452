import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import featureDataOne from "../../data/feature/feature-one.json";
import { getPortfolio } from "../../service/portfolioService";

const [{ paragraph1, paragraph2, subTitle, title, span }] = featureDataOne;

const FeatureSeven = ({ sectionSpace }) => {
  const[portfolio, setPortfolio] = useState([])

  useEffect(()=>{
    getProjects()
  },[]);
  const getProjects = async()=>{
    try{
      const response = await getPortfolio()
      setPortfolio(response.data)
    }
    catch(err){}
  }
  return (
    <Fragment>
    <section
      id="features-section"
      className={`features-section ${sectionSpace} clearfix`}
    >
      <Container>
        <div className="feature-item">
          {portfolio.map((portfolio,index)=>(
            index%2 != 0?
            <Row className="justify-content-lg-between justify-content-md-center">
            <Col lg="6" md="8">
              <div 
              className="feature-content"
              data-aos="fade-right"
              data-aos-delay="200"
              >
                <div className="section-title mb-60">
                  {/* <span className="sub-title mb-15">Serivces</span> */}
                  <h2 className="title-text mb-0">
                    {portfolio.title}
                    {/* <span>{span}</span> */}
                  </h2>
                </div>

                <p className="mb-30">{portfolio.title_description}</p>
              </div>
            </Col>
            <Col lg="5" md="9">
              <div className="feature-image-7 float-right">
                <span
                  className="phone-image"
                  data-aos="fade-left"
                  data-aos-delay="400"
                >
                  <img
                    src={portfolio.demo_image}
                    alt="image_not_found"
                  />
                </span>
                {/* <span
                  className="big-chart"
                  data-aos="zoom-out"
                  data-aos-delay="300"
                >
                  <img
                    src="assets/images/features/animation/9-img-2.png"
                    alt="image_not_found"
                  />
                </span>
                <span
                  className="circle-chart-1"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  <img
                    src="assets/images/features/animation/9-img-3.png"
                    alt="image_not_found"
                  />
                </span>
                <span
                  className="circle-chart-2"
                  data-aos="fade-up"
                  data-aos-delay="600"
                >
                  <img
                    src="assets/images/features/animation/9-img-4.png"
                    alt="image_not_found"
                  />
                </span>
                <span
                  className="circle-chart-3"
                  data-aos="fade-up"
                  data-aos-delay="700"
                >
                  <img
                    src="assets/images/features/animation/9-img-5.png"
                    alt="image_not_found"
                  />
                </span>
                <span
                  className="man-image-1"
                  data-aos="fade-right"
                  data-aos-delay="900"
                >
                  <img
                    src="assets/images/features/animation/9-img-6.png"
                    alt="image_not_found"
                  />
                </span>
                <span
                  className="man-image-2"
                  data-aos="fade-right"
                  data-aos-delay="1000"
                >
                  <img
                    src="assets/images/features/animation/9-img-7.png"
                    alt="image_not_found"
                  />
                </span>
                <span
                  className="man-image-3"
                  data-aos="fade-left"
                  data-aos-delay="1200"
                >
                  <img
                    src="assets/images/features/animation/9-img-8.png"
                    alt="image_not_found"
                  />
                </span>
                <span
                  className="leaf-image"
                  data-aos="flip-left"
                  data-aos-delay="1300"
                >
                  <img
                    src="assets/images/features/animation/9-img-9.png"
                    alt="image_not_found"
                  />
                </span> */}
              </div>
            </Col>
          </Row>
          :
          <Row className="justify-content-lg-between justify-content-md-center">
            <Col lg="5" md="5">
              <div className="feature-image-7 float-right">
              <span
                   className="phone-image"
                   data-aos="fade-right"
                   data-aos-delay="200"
                >
                  <img
                    src={portfolio.demo_image}
                    alt="image_not_found"
                  />
                </span>
                {/* <span className="circle-image">
                  <img
                    src={portfolio.demo_image}
                    alt="image_not_found"
                  />
                </span> */}
                {/* <span
                  className="phone-image"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <img
                    src="assets/images/features/animation/1-img-2.png"
                    alt="image_not_found"
                  />
                </span>
                <span
                  className="comment-image-1"
                  data-aos="fade-left"
                  data-aos-delay="400"
                >
                  <img
                    src="assets/images/features/animation/1-img-3.png"
                    alt="image_not_found"
                  />
                </span>
                <span
                  className="comment-image-2"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src="assets/images/features/animation/1-img-4.png"
                    alt="image_not_found"
                  />
                </span> */}
              </div>
            </Col>
          <Col lg="6" md="8">
              <div 
              className="feature-content"
              data-aos="fade-left"
              data-aos-delay="400"
              >
                <div className="section-title mb-30">
                  {/* <span className="sub-title mb-15">Services</span> */}
                  <h2 className="title-text mb-0">
                    {portfolio.title}
                    {/* <span>{span}</span> */}
                  </h2>
                </div>

                <p className="mb-30">{portfolio.title_description}</p>
              </div>
            </Col>
          </Row>
          ))}
          
        </div>

        {/* <div className="feature-item">
          <Row className="justify-content-lg-between justify-content-md-center">
            <Col lg="5" md="9">
              <div className="feature-image-7 float-left">
                <span
                  className="bg-image"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <img
                    src="assets/images/features/animation/9-img-10.png"
                    alt="image_not_found"
                  />
                </span>
                <span
                  className="big-chart"
                  data-aos="zoom-out"
                  data-aos-delay="300"
                >
                  <img
                    src="assets/images/features/animation/9-img-11.png"
                    alt="image_not_found"
                  />
                </span>
                <span
                  className="man-image-1"
                  data-aos="fade-right"
                  data-aos-delay="900"
                >
                  <img
                    src="assets/images/features/animation/9-img-12.png"
                    alt="image_not_found"
                  />
                </span>
                <span
                  className="man-image-2"
                  data-aos="fade-right"
                  data-aos-delay="1000"
                >
                  <img
                    src="assets/images/features/animation/9-img-14.png"
                    alt="image_not_found"
                  />
                </span>
                <span
                  className="man-image-3"
                  data-aos="fade-left"
                  data-aos-delay="1200"
                >
                  <img
                    src="assets/images/features/animation/9-img-13.png"
                    alt="image_not_found"
                  />
                </span>
                <span
                  className="leaf-image"
                  data-aos="flip-left"
                  data-aos-delay="1300"
                >
                  <img
                    src="assets/images/features/animation/9-img-15.png"
                    alt="image_not_found"
                  />
                </span>
              </div>
            </Col>

            <Col lg="6" md="8">
              <div className="feature-content">
                <div className="section-title mb-30">
                  <span className="sub-title mb-15">Services</span>
                  <h2 className="title-text mb-0">
                    {title}
                    <span>{span}</span>
                  </h2>
                </div>

                <p className="mb-30">{paragraph1}</p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="feature-item">
          <Row className="justify-content-lg-between justify-content-md-center">
          <Col lg="6" md="8">
              <div className="feature-content">
                <div className="section-title mb-30">
                  <span className="sub-title mb-15">Services</span>
                  <h2 className="title-text mb-0">
                    {title}
                    <span>{span}</span>
                  </h2>
                </div>

                <p className="mb-30">{paragraph1}</p>
              </div>
            </Col>
            <Col lg="5" md="5">
              <div className="feature-image-1">
                <span className="circle-image">
                  <img
                    src="assets/images/features/animation/1-img-1.png"
                    alt="image_not_found"
                  />
                </span>
                <span
                  className="phone-image"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <img
                    src="assets/images/features/animation/1-img-2.png"
                    alt="image_not_found"
                  />
                </span>
                <span
                  className="comment-image-1"
                  data-aos="fade-left"
                  data-aos-delay="400"
                >
                  <img
                    src="assets/images/features/animation/1-img-3.png"
                    alt="image_not_found"
                  />
                </span>
                <span
                  className="comment-image-2"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src="assets/images/features/animation/1-img-4.png"
                    alt="image_not_found"
                  />
                </span>
              </div>
            </Col>
          </Row>
        </div> */}
      </Container>
    </section>
  </Fragment>
  );
};

export default FeatureSeven;
