import React, { Component, Fragment, useEffect, useState } from 'react'
import { Table } from 'reactstrap';


const Jobs = ({career})=> {
    return (  
        <Fragment>
            {
                career.length === 0?
                <div className='headline'>
                <h4>No Vacancy Available</h4>
            </div>
            :
            <div>
            <div className='headline'>
                <h4>Current Job Openings</h4>
            </div>
             <div className='table'>
             <Table striped bordered hover center>
                <thead>
                    <tr>
                    <th>Job Title</th>
                    <th>Location</th>
                    <th>Department</th>
                    <th>Emplyement Type</th>
                    <th>View Details</th>
                    </tr>
                </thead>
                <tbody>
                    {career.map((career)=>(
                        <tr>
                        <td>{career.title_one}</td>
                        <td>{career.location}</td>
                        <td>{career.department}</td>
                        <td>{career.employee_type}</td>
                        <td><a className='button' href={`/job-details/${career.id}`}>View</a></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
             </div>
            </div>
            }
        </Fragment>
    );
}

export default Jobs;