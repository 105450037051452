import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { getBlog } from "../../service/blogService";
import { BlogDetails } from "./elements";
import {
  CategoriesWidget,
  CloudTagWidget,
  InstagramWidget,
  NewsLetterWidget,
  PostWidget,
  SearchWidget,
} from "./elements";

const BlogDetailsTwo = ({ sectionSpace , blog }) => {
  const [blogs, setBlogs] = useState([]);
  useEffect(()=>{
    getBlogs()
  },[]);
  const getBlogs = async ()=>{
    try{
      const response = await getBlog()
      setBlogs(response.data)
    }
    catch(err){}
  }
  return (
    <Fragment>
      <section
        id="blog-details-section"
        className={`blog-details-section ${sectionSpace} clearfix`}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg="8" md="10" xs="12">
              <BlogDetails blog={blog} />
              {/* <BlogDetails /> */}
            </Col>
            <Col lg="4" md="6" xs="12">
              <aside id="sidebar-section" className="sidebar-section clearfix">
                {/* <!-- widget_newsletter - start --> */}
                <NewsLetterWidget title="Newsletter" />
                {/* <!-- widget_newsletter - end --> */}

                {/* <!-- widget_cixposttype - start --> */}

                <PostWidget blogs={blogs} title="Recent Post" />
                {/* <!-- widget_cixposttype - end --> */}

              </aside>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default BlogDetailsTwo;
