import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const BlogDetails = ({blog}) => {
  console.log(blog)
  return (
    <Fragment>
      <div className="blog-details-content pb-0 clearfix">
        <h2 className="blog-title mb-30">
        </h2>
        <div className="post-meta ul-li mb-30 clearfix">
          <ul className="clearfix">
            <li>
              <Link to="#" className="post-admin">
               
                Nicolas otamendi
              </Link>
            </li>
            <li>
              <i className="uil uil-stopwatch"></i> 5 Hours ago
            </li>
          </ul>
        </div>
        <h3 className="title-text mb-30">{blog.name}</h3>
        <p className="mb-0" dangerouslySetInnerHTML={{__html:blog.description}}>
    
        </p>
      </div>
    </Fragment>
  );
};

export default BlogDetails;
