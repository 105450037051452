import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/utility";
import { LayoutTwo } from "../../layouts";
import { BannerFive } from "../../components/Banner";
import { BrandSlider} from "../../components/BrandSlider";
import { FeatureFoure } from "../../components/Features";
import BlogSlider from "../../components/BLogSlider/BlogSlider";
import { getWork } from "../../service/workService";
import { getBlog } from "../../service/blogService";
import { getClient } from "../../service/clientService";
const HomePageFive = () => {
  const [work, setWork] = useState([]);
  const [blog, setBlog] = useState([]);
  const [brand,setBrand] = useState([]);

  useEffect(()=>{
    // getBanners()
    // getOffers()
    // getSubOffers()
    getWorkField()
    getBlogs()
    getBrands()
  },[]);

  const getWorkField = async ()=>{
    try{
      const response = await getWork()
      setWork(response.data)
    }
    catch(err){}
  }
  const getBlogs = async ()=>{
    try{
      const response = await getBlog()
      setBlog(response.data)
    }
    catch(err){}
  }
  const getBrands = async ()=>{
    try{
      const response = await getClient()
      setBrand(response.data)
    }
    catch(err){}
  }
  return (
    <Fragment>
      <PageTitle
        PageTitle="Arventure-Home"
        favicon="assets/images/logo/tab-logo.png"
      />
      <LayoutTwo
        activeClass="active"
        sectionSpace="sec-ptb-140 pb-0"
        whitekContent="white-content"
      >
        <main>
          <BannerFive />
          <FeatureFoure work={work}/>
        </main>
      </LayoutTwo>
    </Fragment>
  );
};

export default HomePageFive;
