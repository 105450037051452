import React from "react";
import { Link } from "react-router-dom";
import NewsLeter from "../NewsLetter/NewsLetter";

import { Container, Row, Col } from "reactstrap";
const Footer = ({ sectionSpace }) => {
  return (
    <footer
      id="footer-section"
      className={`footer-section ${sectionSpace} clearfix`}
      style={{ backgroundImage: `url(assets/images/footer-bg-1.png)` }}
    >

      <div
        className="footer-content mb-100 clearfix"
        data-aos="fade-up"
        data-aos-duration="450"
        data-aos-delay="200"
      >
        <Container>
          <Row>
            <Col lg="5" md="6">
              <div className="about-content">
                <div className="brand-logo mb-30">
                  <Link to="/" className="brand-link">
                    <img
                      src="/assets/images/logo/logo-2.png"
                      alt="logo_not_found"
                    />
                  </Link>
                </div>
                <p className="mb-30">
                  Arventure is a software company based in New York, USA. We provide all kind of software solution
                  for your business. We have top notch engieers to ensure quality software solutions.
                </p>
                <div className="social-links ul-li clearfix">
                  <ul className="clearfix">
                    <li>
                      <Link to="/">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg="2" md="6">
              <div className="useful-links ul-li-block clearfix">
                <h3 className="item-title">Useful links</h3>
                <ul className="clearfix">
                  <li>
                    <Link to="/">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/">Terms and Conditions</Link>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg="2" md="6">
              <div className="useful-links ul-li-block clearfix">
                <h3 className="item-title">Arventure</h3>
                <ul className="clearfix">
                  <li>
                    <Link to="contact-us">Contact us</Link>
                  </li>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="career">Career</Link>
                  </li>
                  <li>
                    <Link to="our-works">Our Works</Link>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg="3" md="6">
              <div className="contact-info ul-li-block clearfix">
                <h3 className="item-title">address</h3>
                <ul className="clearfix">
                  <li>
                    <Link to="/">
                      <small className="icon">
                        <i className="uil uil-phone-pause"></i>
                      </small>
                      <span className="info-text">+1 646 207 7011</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <small className="icon">
                        <i className="uil uil-envelope-minus"></i>
                      </small>
                      <span className="email-text">info@arventureny.com</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <small className="icon">
                        <i className="uil uil-location-point"></i>
                      </small>
                      <span className="info-text">
                      Jackson heights,New York City, USA
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <div className="footer-bottom clearfix">
          <Row>
            <Col md="12">
              <p className="copyright-text mb-0">
                Copyright @ 2021{" "}
                <a
                  href=" "
                  target={"_blank"}
                  rel="noopener noreferrer"
                  className="author-link"
                >
                  CodeBreakers
                </a>{" "}
                all right reserved.
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
